.contactsPageContainer,
.centerLayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactsPageContainer {
    margin-top: 75px;
}

.contactsTitle {
    color: #00868E;
    font-weight: bold;
    text-align: center;
}

.contactsSubTitle {
    color: #707070;
    text-align: center;
}

.contactsJoin {
    color: #00868E;
    text-align: center;
    text-decoration: underline;
}

.contactsJoin:hover{
    cursor: pointer;
    color: #D8B74B;
}

.countriesList {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-row-gap: 50px;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: center;
    text-align: center;
}

.countriesList>* {
    margin-left: 25px;
    margin-right: 25px;
}

.columnCountries {
    float: left;
    opacity: 0.42;
    height: 60px;
    width: 89px;
    height: 60px;
    border-radius: 10%;
    border: 1px solid rgb(117, 117, 117);
    object-fit: cover;
}

.columnCountries:hover {
    opacity: 1;
    cursor: pointer;
}

.globeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.globeWrapper > * {
    margin-right: 15px;
}

.globeImg {
    width: 45px;
    cursor: pointer;
}

/* Contact information styling */
.infoLayout {
    width: 100%;
    display: grid;
    justify-content: center;
    overflow: hidden;
    grid-template-columns: auto auto;
    margin-top: 50px;
}

.infoContainer {
    margin: 50px;
}

.rectangle,
.mapContainer {
    width: 542px;
}

.rectangle {
    background-color: #00868E;
    opacity: 0.7;
    border-radius: 2% 0% 0% 2%;
    text-align: left;
}

.cInfo {
    color: white;
    line-height: 1.25em;
    padding: 2px;
}

.btnWebsite {
    margin-top: 5px;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    border-color: white;
    color: #00868E;
    font-weight: bold;
}

.mapContainer {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 100%;
    border-radius: 0% 2% 2% 0%;
}

.mapContainer iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}


@media only screen and (max-width: 1200px) {
    .rectangle,
    .mapContainer {
        width: 400px;
    }
}

@media only screen and (max-width: 991px) {
    .rectangle,
    .mapContainer {
        width: 300px;
    }

    .infoContainer {
        margin: 30px 25px;
    }
}


@media only screen and (max-width: 1200px) {
    .countriesList {
        grid-template-columns: auto auto auto auto;
        grid-row-gap: 30px;
        text-align: center;
    }
}

@media only screen and (max-width: 991px) {
    .countriesList {
        grid-template-columns: auto auto auto;
        grid-row-gap: 30px;
        text-align: center;
    }
}

@media only screen and (max-width: 712px) {
    .infoLayout {
        max-width: 80%;
        grid-template-columns: auto;
    }

    .rectangle,
    .mapContainer {
        width: 100%;
    }

    .rectangle {
        border-radius: 2% 2% 0 0;
    }

    .mapContainer {
        border-radius: 0 0 2% 2%;
    }

    .mapContainer {
        height: 270px;
    }
}

@media only screen and (max-width: 420px) {
    .countriesList {
        grid-template-columns: auto auto;
    }
}
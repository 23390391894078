.exerciseFlexWrapper.c11 {
    margin-top: 30px;
}

.buttonsWrapper {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.questionBox {
    width: 500px;
    height: 300px;
    padding: 20px 40px;
    border-color: #D8B74B;
    border-style: solid;
    border-width: 2px;
    margin-left: 20px;
    border-radius: 15px 15px 15px 15px;
    overflow: auto;
}

.question {
    font-size: 13px;
    color: black;
    text-align: justify;
}

.questionSeparator {
    border-top: 2px solid #00868E;
    width: 100%;
    margin-top: 12px;
    opacity: 0.58;
}

.questionButtonSelected,
.questionButtonNotSelected {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border-color: transparent;
    color: white;
    font-size: 15px;
}

.questionButtonSelected {
    background-color: rgb(231, 206, 125);
}

.questionButtonNotSelected {
    background-color: rgb(135, 199, 202);
}

.answerBox {
    width: 100%;
    height: 130px;    
    border: none;
    margin-top: 12px;
    resize: none;
    color: rgb(57, 11, 110);
    font-size: 13px;
    line-height: 25px;
    background-image: -moz-linear-gradient(top , transparent, transparent 24px,#BDE0E2 0px); 
    background-image: -webkit-linear-gradient(top , transparent, transparent 24px,#BDE0E2 0);
    -webkit-background-size: 100% 25px;
    background-size: 100% 25px;
}

.answerBox:focus {
    outline: none;
}

@media only screen and (max-width: 1270px) {
    .questionButtonSelected,
    .questionButtonNotSelected {
        height: 35px;
        width: 35px;
    }
    
    .questionBox {
        width: 500px;
    }
}

@media only screen and (max-width: 1100px) {
    .buttonsWrapper {
        height: 250px;
    }

    .questionBox {
        height: 250px;
        padding: 20px;
    }

    .answerBox {
        height: 100px; 
    }
}
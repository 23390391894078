.menuNav, .menuCloseNav {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    margin-right: 15px;
}

.mobileNavbar {
    height: 70px;
    position: fixed;
    width: 100%;
    z-index: 2;
    background-color: white;
    display: flex;
    align-items: center;
}

.mobileLogo {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.backgroundNavBarMobile {
    display: flex;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 65px);
    background: #BDE0E2;
    top: 65px;
    left: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mobileNavContent {
    width: 100%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-left: 60px;
    overflow-y: auto;
}

.mobileNavContent > * {
    margin-bottom: 30px;
}

.fieldsDivisionNav {
    display: grid;
    border-top: 2px solid #D8B74B;
    width: 100px;
    opacity: 1;
}

@media only screen and (max-width: 1050px) {
    .navbar-nav {
        height: 70px;
    }

    .logoNav {
        height: 42px;
        margin-right: 60px;
    }

    .leftLink, 
    .dropbtn {
        font-size: 20px;
    }
}

@media only screen and (max-width: 900px) {
    .menuNav, .menuCloseNav {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .leftLink, 
    .dropbtn {
        font-size: 18px;
    }

    .mobileNavContent {
        padding-left: 30px;
    }

    .mobileNavContent > * {
        margin-bottom: 20px;
    }
}
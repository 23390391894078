.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
    overflow: hidden;
}

.not-found-title {
    margin: 20px;
    font-size: 60px;
    font-weight: bold;
    color: #00868e;
    text-align: center;
}

.not-found-description {
    font-size: 20px;
    text-align: center;
}

.not-found-buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.not-found-buttons-wrapper a:hover {
    text-decoration: none;
}

.not-found-button {
    cursor: pointer;
    border-radius: 20px;
    background-color: #d8b74b;
    text-align: center;
    font-size: 20px;
    border: 1px;
    color: white;
    padding: 8px 15px;
    display: block;
    text-decoration: none;
}

.not-found-button.margin {
    margin-top: 20px;
}

@media only screen and (max-width: 1280px) {
    .not-found-title {
        font-size: 50px;
    }
}

@media only screen and (max-width: 991px) {
    .not-found-title {
        font-size: 40px;
    }

    .not-found-button {
        font-size: 15px;
    }
}

@media only screen and (max-width: 400px) {
    .not-found-title {
        font-size: 30px;
    }
    .not-found-description {
        font-size: 18px;
    }
}

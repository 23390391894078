.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550;
  border-radius: 6px;
  box-shadow: 24;
  padding: 14px !important;
}

.moviePhotoGallery .image-gallery-content:not(.fullscreen) .image-gallery-slide {
  height: 500px !important;
}

.moviePhotoGallery .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
  width: 700px !important;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .box {
    width: 90%;
  }

  .moviePhotoGallery .image-gallery-content:not(.fullscreen) .image-gallery-slide {
    height: unset !important;
  }
  
  .moviePhotoGallery .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    width: 100% !important;
  }
}
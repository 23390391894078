.gallery {
    margin-top: 10%;
    margin-left: 10%;
}

.galleryWrapperNE {
    padding-top: 50px;
    margin: 0 auto;
    max-width: 100%;
    justify-content: center;
    justify-items: center;
}

.galleryWrapperNE .image-gallery-content:not(.fullscreen) .image-gallery-image {
    max-height: 300px !important;
}

/** Change stroke color of all svgs in gallery **/
.image-gallery-slide-wrapper svg {
    stroke: #009688;
}

/** Remove filter from gallery icons **/
.image-gallery-slide-wrapper .image-gallery-icon {
    filter: unset;
}

.image-gallery-slide-wrapper .gallery-arrow:hover {
    opacity: 0.7;
}

/** Style gallery bullets **/
.image-gallery-bullets .image-gallery-bullet {
    border: 1px solid #009688;
}

.image-gallery-bullets .image-gallery-bullet.active,
.image-gallery-bullets .image-gallery-bullet.active:hover,
.image-gallery-bullets .image-gallery-bullet:hover {
    border: 1px solid #009688;
    background: #009688;
}

/** Style gallery thumbnails **/
.image-gallery-thumbnail:hover {
    border: 4px solid #009688;
}

.image-gallery-thumbnail.active {
    border: 4px solid #009688;
}
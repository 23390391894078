.authForm.migration {
    padding: 30px;
}

.migrationTitle {
    color: #00868E;
    text-align: center;
    font-weight: bold;
}

.migrationDescription {
    color: #684B1A;
    margin-top: 5px;
    margin-bottom: 25px;
    text-align: center;
}

.migrationHeader {
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 991px) {
    .authForm.migration {
        max-height: 80vh;
        overflow: auto;
    }
}
.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 250px;
	overflow: hidden;
	margin-top: 15%;
	margin-bottom: 15%;
}

.item {
	list-style-type: none;
	transition: 0.5s all ease;
	width: 20px;
	height: 20px;
	margin: 10px;
}

.item:nth-child(1) {
	animation: right-1 1s infinite alternate;
	background-color: #66ecf3;
	animation-delay: 100ms;
}

@keyframes right-1 {
	0% {
		transform: translateY(-60px);
	}

	100% {
		transform: translateY(60px);
	}
}

.item:nth-child(2) {
	animation: right-2 1s infinite alternate;
	background-color: #3ddce4;
	animation-delay: 200ms;
}

@keyframes right-2 {
	0% {
		transform: translateY(-70px);
	}

	100% {
		transform: translateY(70px);
	}
}

.item:nth-child(3) {
	animation: right-3 1s infinite alternate;
	background-color: #1dc8d1;
	animation-delay: 300ms;
}

@keyframes right-3 {
	0% {
		transform: translateY(-80px);
	}

	100% {
		transform: translateY(80px);
	}
}

.item:nth-child(4) {
	animation: right-4 1s infinite alternate;
	background-color: #07a7af;
	animation-delay: 400ms;
}

@keyframes right-4 {
	0% {
		transform: translateY(-90px);
	}

	100% {
		transform: translateY(90px);
	}
}

.item:nth-child(5) {
	animation: right-5 1s infinite alternate;
	background-color: #00868E;
	animation-delay: 500ms;
}

@keyframes right-5 {
	0% {
		transform: translateY(-100px);
	}

	100% {
		transform: translateY(100px);
	}
}
/* Style page header */
.cinedShape {
    height: 650px;
}

.headerContentWrapper {
    margin-bottom: 65px;
}

.collectionStructure {
    margin-top: -41%;
    margin-left: 15%;
    display: flex;
    align-items: flex-start;
}

.headerPhotogramContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;

    padding: 8px;
    --b: 3px;
    --c: #F49800;
    --w: 50px;
    --_g: #0000 90deg,var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    border: var(--b) solid #0000;
    background: conic-gradient(from 90deg at top var(--b) left var(--b),var(--_g)) 0 0 / var(--_p), conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
    border-radius: 5px;
}

.headerPhotogram {
    width: 400px;
    height: 270px;
    object-fit: cover;
    border-radius: 5px;
}

.collectionSubStructure {
    width: 450px;
    display: grid;
    align-self: normal;
}

.headerTitle {
    font-weight: bold;
    color: #00868E;
    line-height: 1.1em;
}

.headerTitleTranslation {
    color: #684B1A;
    font-weight: bold;
    margin-top: -5px;
}

.headerBody { 
    position: relative;
    color: #684B1A;
    height: 92px;
    text-align: justify;
    overflow: hidden;
}

.headerBody,
.headerBody > * {
    font-size: 15px;
    line-height: 1.5em;
    margin: 0;
}

.headerBody::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.5em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.moreInfoHeader {
    color: black;
    font-weight: bold;
    line-height: 1.2em;
    margin-top: 10px;
    text-align: right;
}

.seeMoreContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.seeMoreContainer:hover {
    color: black;
    text-decoration: none;
}

.seeMoreTxt {
    color: black;
    margin-bottom: 8px;
}

.arrowDown {
    height: 23px;
}

/* Style movie collection */
.videoItemsList {
    padding-top: 75px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    row-gap: 50px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#movie-collection,
#thematic-videos {
    padding-top: 125px;
}

/* Extra large devices (up to 1650px) */
@media only screen and (max-width: 1650px) {
    .gridShape {
        max-width: 1026px;
        margin-left: auto;
        margin-right: auto;
    }
    .cinedShape {
        max-width: 100%;
    }
    .collectionStructure {
        margin-top: -44.5%;
        margin-left: 15%;
    }
    .headerPhotogramContainer {
        margin-right: 20px;
    }
    .headerPhotogram {
        width: 340px;
        height: 230px;
    }
    .collectionSubStructure {
        width: 403px;
    }
    .videoItemsList {
        grid-template-columns: auto auto auto;
    }
}

@media only screen and (max-width: 1280px) {
    .gridShape {
        max-width: 940px;
    }
    .collectionStructure {
        margin-top: -47.5%;
        margin-left: 13.5%;
    }
    .headerPhotogram {
        width: 300px;
        height: 202px;
    }
}

@media only screen and (max-width: 1170px) {
    .headerContainer .container {
        width: unset;
    }
    .gridShape {
        max-width: 800px;
    }
    .collectionStructure {
        margin-top: -56%;
        margin-left: 15%;
    }
    .headerPhotogram {
        width: 267px;
        height: 180px;
    }
    .collectionSubStructure {
        width: 310px;
    }
    .headerBody {
        height: 70px;
    }
    .headerBody, .headerBody > * {
        font-size: 15px;
    }
    .moreInfoHeader {
        margin-top: 5px;
    }
    .videoItemsList {
        padding-top: 50px;
        grid-template-columns: auto auto;
    }
    #movie-collection,
    #thematic-videos {
        padding-top: 100px;
    }
}

@media only screen and (max-width: 991px) {
    .headerContentWrapper {
        margin-top: 125px;
        margin-bottom: 60px;
    }
    .gridShape {
        max-width: 70%;
    }
    .cinedShape {
        display: none;
    }
    .headerPhotogramContainer {
        margin-right: 20px;
    }
    .headerPhotogram {
        width: 200px;
        height: 200px;
        border-radius: unset;
    }
    .headerBody {
        height: 92px;
    }
    .collectionStructure {
        align-items: center;
        margin-top: 0;
        margin-left: 0;
    }
    .collectionSubStructure {
        width: unset;
    }
    .videoItemsList {
        max-width: 85%;
    }
}

@media only screen and (max-width: 764px) {
    .gridShape {
        max-width: 85%;
    }
}

@media only screen and (max-width: 650px) {
    .gridShape {
        max-width: 90%;
    }
    .headerContentWrapper {
        margin-bottom: 40px;
        margin-top: 100px;
    }
    .headerPhotogram {
        width: 170px;
        height: 170px;
        object-fit: cover;
    }
    .videoItemsList {
        max-width: 100%;
        grid-template-columns: auto;
        row-gap: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .gridShape {
        max-width: 100%;
    }
    .headerContentWrapper {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 470px) {
    .gridShape {
        max-width: 90%;
    }
    .collectionStructure {
        flex-direction: column;
    }
    .headerPhotogramContainer {
        margin-right: 0;
    }
    .headerPhotogram {
        width: 100%;
        max-height: 180px;
        height: unset;
    }
    .collectionSubStructure {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 350px) {
    .gridShape {
        max-width: 100%;
    }
}
.exerciseFlexWrapper.a1 {
    margin-top: 45px;
    margin-bottom: 25px;
}

.exerciseFlexWrapper >*:not(:first-child) {
    margin-left: 20px;
}

.dragDropWrapper {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 30px;
    row-gap: 20px;
}

.dragDropBox {
    height: 99px;
    width: 153px;
    border-radius: 10px;
    border-color: #00868E;
    border-style: dashed;
    border-width: 2px;
}

.dragDropBox .EnlargedImage__Image,
.imagesA1 .EnlargedImage__Image {
    width: 149px !important;
    height: 95px !important;
    border-radius: 10px;
    object-fit: cover;
}

.dragDropBox .imagesA1Drop,
.dragDropBox .imagesA1 .EnlargedImage__Image {
    margin-top: 1px;
}

.answerBoxA1 {
    width: 150px;
    text-align: center;
    height: 60px;
    border: 2px solid transparent;
    border-radius: 10px;
    margin-top: 6%;
    resize: none;
    color: rgb(0, 0, 0);
    font-size: 13px;
}

.line {
    border-left: 2px solid #D8B74B;
    height: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0.58;
}

.imagesA1Wrapper {
    height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}

.imagesA1Wrapper>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagesA1Wrapper>div:not(:last-child) {
    margin-bottom: 18px;
}

.imagesA1Wrapper.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.modalContentSS {
    margin: auto auto auto auto;
    border-radius: 4px;
    padding-top: 30px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: white;
}

.modalContentSS .modalBody {
    padding-bottom: 20px;
}

.modalHelpWrapper {
    display: flex;
    text-align: center;
    margin-top: 25px;
}

.modalHelpWrapper>* {
    width: 150px;
}

.modalHelpWrapper>*:not(:first-child) {
    margin-left: 20px;
}

.textModalSS {
    font-size: 14px;
    color: black;
    text-align: center;
}

.iconExplanation {
    margin-top: 10px;
    min-width: 135px;
    font-size: 14px;
    color: black;
}

.centerButton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1270px) {
    .dragDropBox {
        height: 85px;
        width: 135px;
    }

    .dragDropWrapper {
        column-gap: 15px;
        row-gap: 5px;
    }

    .answerBoxA1 {
        width: 135px;
        font-size: 12px;
    }

    .imagesA1Wrapper>div:not(:last-child) {
        margin-bottom: 10px;
    }

    .dragDropBox .EnlargedImage__Image,
    .imagesA1 .EnlargedImage__Image {
        width: 131px !important;
        height: 81px !important;
    }

    .modalContentSS {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 1100px) {
    .dragDropBox {
        height: 75px;
        width: 110px;
    }

    .dragDropBox .EnlargedImage__Image {
        width: 106px !important;
        height: 71px !important;
    }

    .dragDropWrapper {
        margin-top: 0%;
        column-gap: 8px;
        row-gap: 3px;
    }

    .answerBoxA1 {
        width: 110px;
    }

    .line {
        height: 250px;
    }

    .imagesA1Wrapper {
        height: 260px;
    }
}


@media only screen and (max-width: 660px) {
    .modalContentSS {
        padding-top: 15px;
        padding-left: 0;
        padding-right: 0;
    }
}
.subtitleExerciseBox {
    color: #00868E;
    font-size: 15px;
    text-align: center;
}

.imagesWrapper.exC23 > *:not(:first-child) {
    margin-left: 40px;
}

.uploadFileBox.exC23 {
    height: 130px;
    width: 160px;
}

.imageUploadedEx23 {
    max-height: 120px;
    max-width: 150px;
}

@media only screen and (max-width: 1100px) {
    .imagesWrapper.exC23 > *:not(:first-child) {
        margin-left: 20px;
    }

    .uploadFileBox.exC23 {
        height: 120px;
        width: 150px;
    }

    .imageUploadedEx23 {
        max-height: 110px;
        max-width: 140px;
    }
}


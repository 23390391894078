.navbar {
  position: fixed;
  width: 100%;
  z-index: 2;
  background-color: white;
}

.logoNav {
  height: 45px;
  margin-right: 10px;
}

/* User dropdown styling */
.userDropdown {
  position: relative;
}

.userLink {
  display: flex;
  align-items: center;
  gap: 5px;
}

.userLink label {
  margin: 0;
}

.userLink svg {
  transform: scale(1.5);
}

.userLink:focus,
.userLink:hover {
  text-decoration: none;
  color: #00868e;
}

.userDropdown .submenuDropdown {
  min-width: 150px;
  right: 0;
}

.downloadAnchor {
  display: flex !important;
  align-items: center;
  gap: 5px;
}
.spanLink {
  cursor: pointer;
}
/* User dropdown styling */

/* Movie dropdown styling */
.dropdownMovies,
.userDropdown {
  float: left;
}

.dropdownMovies:hover .submenuDropdown,
.userDropdown:hover .submenuDropdown {
  display: block;
}

.submenuDropdown > * {
  float: none;
  color: #00868e;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
}

.downloadAnchor .MuiSvgIcon-root {
  fill: #00868e !important;
}

.submenuDropdown > *:hover {
  text-decoration: none;
  background-color: #d8b74b13;
  color: #d4af37;
}

.submenuDropdown > *:hover .MuiSvgIcon-root {
  fill: #d4af37 !important;
}

/* Movie dropdown styling */
.leftLink,
.rightLink,
.userLink,
.dropbtn {
  font-size: 18px;
  cursor: pointer;
}

.leftLinksContainer > *,
.rightLinksContainer > * {
  margin-right: 20px;
}

.leftLinksContainer,
.rightLinksContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.leftLinksContainer {
  margin-left: 50px;
}

.rightLinksContainer {
  margin-left: 20px;
}

.leftLink,
.userLink {
  color: #00868e;
  font-weight: bold;
}

.navbarImgOption img {
  filter: drop-shadow(2px 2px 2px #999);
  width: 35px;
}

.navbarImgOption:hover img {
  opacity: 0.8;
}

.rightLink {
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.leftLink:hover,
.rightLink:hover,
.activeLink {
  color: #d4af37;
  text-decoration: none;
}

.navbar-placeholder {
  height: 62px;
  margin-bottom: 20px;
}

.navbar .navbar-nav,
.navbar .navbarRight {
  height: 60px;
}

.navbar .navbarRight {
  float: right;
  display: flex;
  margin-right: 50px;
}

.codeBoxWrapper {
  display: flex;
  height: 100%;
  align-items: center;
}

.codeBoxWrapper > *:not(:first-child) {
  margin-left: 7px;
}

.navbar input[type="code"] {
  width: 126px;
  height: 20px;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 4%;
  color: black;
  text-align: center;
  font-size: 14px;
  border: none;
  resize: none;
  outline: none;
}

.shareCodeFormStudents {
  height: 30px;
  width: 136px;
  border: 1.5px solid #d4af37;
  box-shadow: 0 4px 8px 0 #d4af37, 0 0px 6px 0 #d4af37;
}

.invalidCode,
.validCode {
  font-size: 12px;
  margin-top: -1px;
  text-align: center;
}

.invalidCode {
  color: red;
}

.validCode {
  color: green;
}

.codeBoxGo {
  cursor: pointer;
}

@media only screen and (max-width: 1600px) {
  .leftLink,
  .rightLink,
  .userLink,
  .dropbtn {
    font-size: 15px;
  }

  .leftLinksContainer > *,
  .rightLinksContainer > * {
    margin-right: 15px;
  }

  .rightLinksContainer {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1366px) {
  .leftLink,
  .rightLink,
  .userLink,
  .dropbtn {
    font-size: 13.5px;
  }

  .leftLinksContainer {
    margin-left: 15px;
  }

  .navbar .navbarRight {
    margin-right: 15px;
  }

  .rightLinksContainer {
    margin-left: 12px;
  }

  .leftLinksContainer > *,
  .rightLinksContainer > * {
    margin-right: 12px;
  }
}

@media only screen and (max-width: 1150px) {
  .navbar .navbarRight {
    margin-right: 0;
    margin-left: 0;
  }

  .leftLinksContainer > *,
  .rightLinksContainer > *:not(:last-child) {
    margin-right: 8px;
  }

  .rightLinksContainer > *:last-child {
    margin-right: 0;
  }

  .rightLinksContainer {
    margin-left: 8px;
  }

  .shareCodeFormStudents {
    width: 110px;
  }

  .navbar input[type="code"] {
    width: 105px;
  }
}

.containerArrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    grid-column: 2/span 3;
    bottom: -50px;
    width: 950px;
}

.previousExerciseButton,
.nextExerciseButton {
    font-size: 16px;
    font-weight: bold;
    color: #00868E;
    cursor: pointer;
}

.previousExerciseButton:hover,
.nextExerciseButton:hover {
    text-decoration: none;
    opacity: 0.8;
    color: #00868E;
}

.nextExerciseDescription {
    width: 350px;
    position: absolute;
    right: 0;
    color: rgb(117, 117, 117);
    font-size: 14px;
    text-align: right;
}

@media only screen and (max-width: 1270px) {
    .containerArrows {
        width: 850px;
    }
}

@media only screen and (max-width: 1100px) {
    .containerArrows {
        width: 700px;
    }
}
.structureGridModal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 32px;
    margin-right: 32px;
}

.modalImgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border: 1px solid #00868e;
    flex-basis: 90px;
    flex-grow: 0;
    flex-shrink: 0;
}

.modalLogoHeader {
    max-width: 75px;
    max-height: 75px;
}

.modalTitleHeader {
    width: 220px;
    margin-left: 20px;
    margin-right: 20px;
    flex-grow: 1;
    text-align: center;
}

.modalButton {
    min-height: 45px;
    cursor: pointer;
    border-radius: 30px;
    background-color: #00868E;
    text-align: center;
    font-weight: bold;
    color: white;
    border: none;
    opacity: 0.7;
    text-decoration: none;
    padding: 8px;
    min-width: 90px;
}

.modalButton:hover {
    opacity: 1
}

.modalButton:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.textModal {
    color: #684B1A;
    font-weight: 400;
    text-align: justify;
}

.modal {
    text-align: center;
}

.modalBody {
    text-align: justify;
    margin-left: 20px;
    margin-right: 20px;
}

@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: "";
        height: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .structureGridModal {
        flex-direction: column;
        margin-left: 12px;
    }
}

@media only screen and (max-width: 420px) {
    .modalTitleHeader {
        width: unset;
    }
    .modalBody {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.exerciseContainer.exC22 {
    flex-direction: column;
}

.exerciseContainer.exC22 > *:not(:first-child) {
    margin-top: 45px;
}

.imagesWrapper.exC22 > *:not(:first-child) {
    margin-left: 60px;
}
 
.imageExerciseC22,
.uploadFileBox.exC22 {
    height: 130px;
    width: 190px;
}

.imageUploadedEx22 {
    max-height: 120px;
    max-width: 180px;
}

@media only screen and (max-width: 1270px) {
    .imageExerciseC22,
    .uploadFileBox.exC22 {
        width: 180px;
        height: 120px;
    }

    .imageUploadedEx22 {
        max-width: 170px;
        max-height: 110px;
    }
}

@media only screen and (max-width: 1100px) {
    .imageExerciseC22,
    .uploadFileBox.exC22 {
        height: 100px;
        width: 160px;
    }

    .imageUploadedEx22 {
        max-width: 150px;
        max-height: 90px;
    }
}

.headerTitleNew {
    font-weight: bold;
    color: #00868E;
}

.buttonsWrapperNE {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    padding-top: 125px;
}

.buttonsWrapperNE > * {
    margin-left: 15px;
    margin-right: 15px;
}

.buttonNE {
    cursor: pointer;
    border-radius: 15px;
    padding: 0px 12px;
    opacity: 0.9;
    text-decoration: none;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    border: none;
}

.buttonNE:not(.selected) {
    background-color: transparent;
    color: black;
}

.buttonNE.selected {
    background-color: #00868E;
    color: white;
}

/* News/Events listing */
.newEventStructure {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
    row-gap: 75px;
}

.newsEventsWrapper {
    display: flex;
    justify-content: center;
}

.newsEventsWrapperInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.imageContainerNE {
    margin-right: 20px;
}

.imageNE {
    height: 150px;
    width: 200px;
    border-radius: 5%;
    object-fit: cover;
}

.typeNE {
    color: #00868E;
    font-weight: bold;
    margin-top: 0;
    line-height: 1em;
}

.titleNE {
    position: relative;
    overflow: hidden;
    margin-top: -2%;
    width: 300px;
    color: black;
    height: 82px;
    line-height: 1.2em;
}

.titleNE::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.dateNE {
    color: #707070;
    font-size: 13px;
    line-height: 1em;
}

.readMore {
    color: #00868E;
    text-decoration: underline;
    cursor: pointer;
    line-height: 1em;
}

@media only screen and (max-width: 1280px) {
    .headerTitleNew {
        margin-top: 14px;
    }

    .newsContainer.container {
        width: 95%;
    }

    .titleNE {
        height: 72px;
    }

    .imageContainerNE {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 1170px) {

    .newEventStructure {
        grid-template-columns: auto;
        row-gap: 50px;
    }

    .buttonsWrapperNE {
        padding-top: 100px;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 991px) {
    .headerTitleNew {
        margin-top: 0px;
        line-height: 1.2em;
    }

    .buttonsWrapperNE>* {
        margin-left: 10px;
        margin-right: 10px;
    }

    .buttonNE {
        font-size: 20px;
    }
}

@media only screen and (max-width: 660px) {
    .titleNE {
        height: 68px;
    }
}

@media only screen and (max-width: 600px) {
    .buttonsWrapperNE>* {
        margin-right: 5px;
        margin-left: 5px;
    }
    
    .buttonNE {
        font-size: 19px;
    }
    
    .imageNE {
        width: 180px;
        height: 180px;
    }

    .titleNE {
        width: 200px;
    }
}

@media only screen and (max-width: 470px) {
    .newsContainer.container {
        width: 100%;
    }

    .newsEventsWrapper {
        width: 100%;
    }

    .newsEventsWrapperInfo {
        flex-grow: 1;
    }

    .imageNE {
        width: 120px;
        height: 150px;
        border-radius: unset;
    }

    .titleNE {
        width: unset;
    }

}

@media only screen and (max-width: 350px) {
    .imageNE {
        width: 100px;
        height: 140px;
        border-radius: unset;
    }
}
.gridIconTitle {
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: 2%;
    position: relative;
}

/** Add right margin to icons (last child of gridIconTitle) **/
.gridIconTitle >*:last-child {
    margin-right: 15px;
}

.titleExerciseBox {
    width: 100%;
    position: absolute;
    text-align: center;
    color: #D4AF37;
    font-size: 18px;
    text-transform: uppercase;
    z-index: -1;
}

.iconsCursor {
    cursor: pointer;
}

.iconsWrapper {
    display: flex;
}

.iconsWrapper > *:not(:first-child),
.iconsWrapper > div > *:not(:first-child) {
    margin-left: 10px;
}

@media only screen and (max-width: 1270px) {
    .iconsCursor {
        height: 30px;
    }
}

@media only screen and (max-width: 1100px) {
    .titleExerciseBox {
        font-size: 16px;
    }
}

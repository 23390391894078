.gridContainerStudentSheets {
    display: grid;
    gap: 10px;
    column-gap: 1px;
    position: relative;
    padding-bottom: 25px;
}

.exerciseFlexWrapper {
    display: flex;
    justify-content: center;
}

.exerciseMenu,
.shareableCodeContainer {
    width: 235px;
    margin-left: 100px;
    border-radius: 5% 5% 5% 5%;
}

.exerciseMenu {
    grid-column: 1 ;
    grid-row: 1;
    margin-top: 25px;
    background-color: #F2F2F2;
    padding-right: 10px;
    padding-bottom: 10px;
}

.shareableCodeContainer {
    grid-column: 1;
    grid-row: 2;
    margin-top: 5px;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
}

.exerciseBoxForm {
    grid-column: 2 / span 3;
    grid-row: 1 / span 2;
    height: 490px;
    width: 950px;
    box-shadow: 0 4px 8px 0 #00868E, 0 0px 6px 0 #00868E;
    border: 1px solid #00868E;
    margin-top: 25px;
    border-radius: 2% 2% 2% 2%;
    position: relative;
}

.titleMenuExercise,
.selectedMainMenuExercise,
.selectedMenuExercise {
    color: #D4AF37;
    font-weight: bold;
}

.sectionMenuExercise {
    color: #00868E;
    font-weight: bold;
}

.titleMenuExercise {
    font-size: 18px;
    line-height: 1.2em;
    margin-left: 8%;
    margin-top: 8%;
}

.sectionMenuExercise,
.selectedMainMenuExercise {
    font-size: 15px;
    line-height: 1em;
    margin-left: 8%;
}

.selectedMenuExercise,
.subsectionMenuExercise {
    font-size: 14px;
    margin-left: 16%;
    line-height: 0.9em;
}

.subsectionMenuExercise {
    color: black;
}

.shareableCodeTitle {
    font-size: 18px;
    line-height: 1.2em;
    font-weight: bold;
    color: #00868E;
    text-align: center;
    margin-top: 5%;
}

.codeForm {
    font-size: 15px;
    font-weight: bold;
    color: black;
    text-align: center;
}

/** Hide images with no source (Safari issue) **/
img[src=""],
img:not([src]) {
    display: none;
}

.ssNormalText {
    font-size: 14px;
    color: #684B1A;
}

.boldText {
    font-weight: 500;
}

@media only screen and (max-width: 1350px) {
    .exerciseMenu,
    .shareableCodeContainer {
        margin-left: 40px;
    }
}

@media only screen and (max-width: 1270px) {
    .exerciseMenu,
    .shareableCodeContainer {
        width: 225px;
    }

    .exerciseBoxForm {
        height: 440px;
        width: 850px;
    }

    .titleMenuExercise,
    .shareableCodeTitle {
        font-size: 16px;
    }

    .sectionMenuExercise,
    .selectedMainMenuExercise,
    .codeForm {
        font-size: 14px;
    }

    .selectedMenuExercise,
    .subsectionMenuExercise,
    .ssNormalText {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1220px) {
    .exerciseMenu,
    .shareableCodeContainer {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 1140px) {
    .exerciseMenu,
    .shareableCodeContainer {
        width: 205px;
        margin-left: 10px;
    }

    .sectionMenuExercise,
    .selectedMainMenuExercise,
    .codeForm {
        font-size: 13px;
    }

    .selectedMenuExercise,
    .subsectionMenuExercise,
    .ssNormalText {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1100px) {
    .exerciseBoxForm {
        height: 400px;
        width: 700px;
        margin-left: 0.5%;
    }
}
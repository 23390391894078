.dropdown {
    position: relative;
    display: inline-block;
    width: 60px;
}

.changeLanguageWrapper {
    display: flex;
    align-items: center;
}

.changeLanguageWrapper>*:not(:first-child) {
    margin-left: 0px;
}

.dropbtn {
    background-color: transparent;
    color: #00868E;
    font-weight: bold;
    margin-top: 0%;
    border: none;
    cursor: pointer;
    padding: 0 8px 0 0;
}

.dropdown svg {
    width: 20px;
}

.dropbtnSide {
    display: none;
}

.lngDropdowncontent,
.submenuDropdown {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.lngDropdowncontent {
    margin-left: -50px;
    max-height: 400px;
    overflow: auto;
}

.lngDropdowncontent li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 18px;
    cursor: pointer;
}

.lngDropdowncontent li:hover {
    background-color: #f1f1f1
}

.dropdown:hover .lngDropdowncontent {
    display: block;
}

@media only screen and (max-width: 1280px) {
    .lngDropdowncontent {
        max-height: 360px;
    }

    .lngDropdowncontent li {
        padding: 10px 12px;
        font-size: 15px;
    }
}

@media only screen and (max-width: 1050px) {
    .dropdown {
        width: 100px;
    }

    #dropdownNavbar {
        display: none;
    }

    .dropbtnSide {
        display: block;
        margin-top: -5px;
    }

    .dropdown .lngDropdowncontent {
        margin-left: 60px;
        bottom: 0; 
    }
}
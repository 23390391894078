.sliderContainer {
    position: relative;
}

.sliderContainer .slick-list {
    background-image: url('../../assets/images/about-project/testimonials.svg');
    background-size: 100%;
}

.sliderContainer .slick-prev, 
.sliderContainer .slick-next {
    z-index: 1;
    width: 34px;
    height: 34px;
}

.sliderContainer .slick-prev {
    left: 25px;
}

.sliderContainer .slick-next {
    right: 25px;
}

.sliderContainer .slick-prev:hover,
.sliderContainer .slick-next:hover {
    text-decoration: none;
    opacity: 0.7;
}

.testimonialsCentered {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.testimonialsBody {
    color: white;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: center;
}

.testimonialsArrows {
    justify-content: space-between;
}

.testimonialsCarousel {
    height: 305px;
    position: relative;
    margin: 0 auto;
    text-align: center;
}

.testimonialsTitle {
    width: 100%;
    position: absolute;
    z-index: 1;
    text-align: center;
}

.testimonialsTitle h2 {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

@media only screen and (max-width: 1450px) {
    .sliderContainer {
        margin-right: -50px;
        margin-left: -50px;
    }
}

@media only screen and (max-width: 1170px) {
    .sliderContainer {
        margin-right: -15px;
        margin-left: -15px;
    }

    .testimonialsBody {
        padding-left: 120px;
        padding-right: 120px;
    }
}

@media only screen and (max-width: 991px) {
    .sliderContainer .slick-list {
        background-size: cover;
    }

    .testimonialsTitle h2 {
        color: white;
        text-transform: uppercase;
        font-weight: bold;
    }
}

@media only screen and (max-width: 764px) {
    .testimonialsCarousel {
        height: 400px;
    }

    .testimonialsBody {
        padding-right: 70px;
        padding-left: 70px;
        line-height: 1.3em;
    }

    .sliderContainer .slick-prev {
        margin-left: -5px;
        width: 23px;
    }
    
    .sliderContainer .slick-next {
        margin-right: -5px;
        width: 23px;
    }
}

@media only screen and (max-width: 470px) {
    .sliderContainer {
        margin-right: -20px;
        margin-left: -20px;
    }
    .testimonialsCarousel {
        height: 450px;
    }
    .testimonialsBody {
        padding-right: 50px;
        padding-left: 50px;
    }
    .sliderContainer .slick-prev {
        margin-left: -10px;
    }
    .sliderContainer .slick-next {
        margin-right: -10px;
    }
}

@media only screen and (max-width: 350px) {
    .testimonialsCarousel {
        height: 480px;
    }
    .testimonialsBody {
        padding-right: 40px;
        padding-left: 40px;
    }
    .sliderContainer .slick-prev {
        margin-left: -15px;
    }
    .sliderContainer .slick-next {
        margin-right: -15px;
    }
}
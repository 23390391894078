/* News/Events detail styling */
.backNEWrapper {
    display: flex;
    align-items: center;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 75px;
}

.backNEWrapper .backNEText {
    color: black;
    font-weight: 500;
    font-size: 1.7em;
    margin-left: 10px;
    margin-top: 4px;
}

.backNEWrapper .backNEText:hover {
    color: black;
    text-decoration: none;
}

.backNEWrapper img {
    height: 20px;
}

.newsDetailsContainer {
    margin-top: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.typeNEDetails {
    color: #00868E;
    font-weight: bold;
}

.titleNEDetails {
    color: black;
    font-weight: bold;
    margin: 16px 0;
}

.labelWrapperNEDetails {
    display: flex;
    text-align: center;
    justify-content: center;
}

.labelWrapperNEDetails>* {
    margin-right: 10px;
    margin-left: 10px;
}

.dateAuthorNEDetails {
    color: #7c5a1f;
}

.textNEDetails {
    margin-top: 16px;
    font-size: 16px;
    letter-spacing: .5px;
    white-space: pre-line;
    text-align: justify;
    line-height: 1.4em;
}

.textNEDetails::first-letter {
    font-size: 50px;
    color: black;
    font-weight: bold;
}

.linkNEDetails {
    word-break: break-all;
}

@media only screen and (max-width: 991px) {
    .backNEWrapper,
    .newsDetailsContainer {
        max-width: 550px;
    }
    .backNEWrapper {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 660px) {
    .backNEWrapper,
    .newsDetailsContainer {
        max-width: 400px;
    }

    .textNEDetails {
        font-size: 14px;
    }

    .textNEDetails::first-letter {
        font-size: 40px;
    }
}

@media only screen and (max-width: 470px) {
    .backNEWrapper,
    .newsDetailsContainer {
        max-width: 90%;
    }

    .labelWrapperNEDetails {
        flex-direction: column;
    }
}

.App {
    min-height: 100vh;
}

.homepage-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/ezgif.com-gif-maker.gif');
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.app-background::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px); /* apply the blur */
    pointer-events: none; /* make the overlay click-through */
  }

.hp-navbar {
    top: 0;
    right: 0;
    display: flex;
    margin-top: 30px;
    margin-right: 50px;
    float: right;
    position: absolute;
}

.hp-navbar >*:first-child {
    margin-right: 20px;
}

.hp-dropdown {
    width: 60px;
}

.hp-navbar svg {
    width: 20px;
}

.hp-dropdown:hover .lngDropdowncontent {
    display: block;
}

/* sign in button */
.hp-navbar .rightLink,
/* user dropdown label */
.hp-navbar .userDropdown label,
/* user dropdown svg */
.hp-navbar .MuiSvgIcon-root,
/* language dropdown label */
.hp-dropdown .dropbtn,
/* language dropdown svg */
.hp-dropdown path {
    color: white;
    fill: white;
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
}

.logoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 200px;
}

.logoWrapper img {
    position: relative;
    top: 1em;
    width: 125px;
    margin: 0 auto;
}

.slogan {
    font-size: 18px;
    position: relative;
    padding-top: 15px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin: 0 auto;
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
}

.numberContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 20px;
}

.numberItem {
    display: flex;
    flex-direction: column;
    width: 190px;
}

.numberItem img {
    height: 23px;
}

.numberItem .numberValue,
.numberItem .numberDescription {
    text-align: center;
    color: rgba(212, 175, 55, 0.9);
    text-shadow: 1px 1px rgba(1, 1, 1, 0.8);
}

.numberItem .numberValue {
    font-size: 26px;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: 'D-DIN', Bahnschrift, sans-serif;
}

.numberItem .numberDescription {
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 1.2em;
}

.numbers {
    position: relative;
    width: 50em;
    margin: 0 auto;
    display: block;
}

.gridContainer {
    max-width: 1000px;
    width: 70%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
}

.home-icon-border {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/images/homepage/btn-border.svg");
    background-repeat: no-repeat;
}

.home-icon-wrapper {
    position: relative;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 300ms linear;
}

.home-icon-wrapper.collection svg {
    margin-right: 35px;
}

.desktop-icon:hover h4 {
    color: #d4af37;
}

.home-icon-label {
    width: 180px;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-weight: 700;
    font-size: 20px;
    text-shadow: 2px 2px black;
    transition: color 300ms linear;
}

.gridItem {
    text-align: center;
}

.gridItem a {
    text-decoration: none;
}

.gridItem a button {
    width: 240px;
    margin: 5px;
}

.btnMovie,
.btnNew,
.btnProject,
.btnAtlas {
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 38px;
    opacity: 0.9;
    transition: 0.3s;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
}

.btnMovie:hover,
.btnNew:hover,
.btnProject:hover,
.btnAtlas:hover {
    opacity: 0.6;
}

.btnMovie:active,
.btnNew:active,
.btnProject:active,
.btnAtlas:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.btnMovie {
    background-color: #D4AF37;
    color: #000000;
    border: #D4AF37;
}

.btnNew {
    background-color: #FFFFFF;
    color: #000000;
    border: #FFFFFF;
}

.btnProject {
    background-color: #00868E;
    color: white;
    border: #00868E;
}

.btnAtlas {
    background-color: #3C216B;
    color: white;
    border: #3C216B;
}

.fadeInText {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2em;
    text-shadow: 2px 2px rgba(1, 1, 1, 0.5);
    margin: auto auto;
    display: block;
}

.gridText1,
.gridText2,
.gridText3,
.gridText4,
.gridText5,
.gridText6 {
    position: absolute;
    grid-template-columns: auto;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.gridText1 {
    width: 220px;
    left: 30%;
    top: 15%;
}

.gridText2 {
    left: 18%;
    top: 50%;
}

.gridText3 {
    left: 30%;
    top: 75%;
}

.gridText4 {
    left: 80%;
    top: 25%;
}

.gridText5 {
    width: 220px;
    left: 75%;
    top: 45%;
}

.gridText6 {
    left: 85%;
    top: 75%;
}

.fadeIn3s {
    animation: fadeOut 3s alternate;
    -webkit-animation: fadeOut 3s alternate infinite;
    -moz-animation: fadeOut 3s alternate infinite;
    -o-animation: fadeOut 3s alternate infinite;
}

.fadeIn35s {
    animation: fadeOut 3.5s alternate;
    -webkit-animation: fadeOut 3.5s alternate infinite;
    -moz-animation: fadeOut 3.5s alternate infinite;
    -o-animation: fadeOut 3.5s alternate infinite;
}

.fadeIn4s {
    animation: fadeOut 4s alternate;
    -webkit-animation: fadeOut 4s alternate infinite;
    -moz-animation: fadeOut 4s alternate infinite;
    -o-animation: fadeOut 4s alternate infinite;
}

.fadeIn5s {
    animation: fadeOut 5s alternate;
    -webkit-animation: fadeOut 5s alternate infinite;
    -moz-animation: fadeOut 5s alternate infinite;
    -o-animation: fadeOut 5s alternate infinite;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@-moz-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@-webkit-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@-o-keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

/* Extra large devices (large laptops and desktops, up to 1366px) */
@media only screen and (max-width: 1366px) {
    .hp-navbar {
        margin-top: 25px;
        margin-right: 25px;
    }
    .hp-navbar >*:first-child {
        margin-right: 15px;
    }
    .gridContainer {
        width: 80%;
    }
    .numberItem {
        width: 160px;
        padding: 3px;
    }
    .numberItem .numberValue {
        font-size: 24px;
    }
}

/* Medium and large devices (landscape tablets and laptops/desktops up to 1200px) */
@media only screen and (max-width: 1200px) {
    .logoWrapper {
        width: 180px;
    }
    .logoWrapper img {
        width: 100px;
    }
    .gridText1 {
        width: 150px;
        left: 32%;
    }
    .gridText5 {
        width: 150px;
        left: 80%;
    }
    .fadeInText,
    .slogan,
    .btnMovie, .btnNew, 
    .btnProject, .btnAtlas,
    .hp-navbar .dropbtn,
    .hp-navbar .rightLink {
        font-size: 14px;
    }
    .gridContainer {
        width: 85%;
    }
    .home-icon-label {
        font-size: 18px;
    }
    .numberItem {
        width: 150px;
        padding: 3px;
    }
    .numberItem .numberValue {
        font-size: 22px;
    }
    .numberItem .numberDescription {
        font-size: 12px;
    }
}

@media only screen and (max-width: 991px) {
    .hp-navbar .userDropdown {
        display: none;
    }

    .gridContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
}

/* Small devices devices (portrait tablets and large phones up to 768px) */
@media only screen and (max-width: 768px) {
    .hp-navbar {
        margin-right: 10px;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
    .hp-navbar .dropdownContainer {
        margin-top: 8px;
    }
    .logoWrapper {
        width: 150px;
    }
    .logoWrapper img {
        width: 80px;
    }
    .gridText1 {
        width: 125px;
    }
    .gridText2 {
        width: 105px;
    }
    .gridText3 {
        width: 120px;
    }
    .gridText4 {
        width: 115px;
    }
    .gridText6 {
        display: none;
    }
    .btnMovie, .btnNew, 
    .btnProject, .btnAtlas {
        padding: 12px 8px;
    }
    .numberItem {
        width: 130px;
    }
}

/* Extra small devices (phones up to 600px) */
@media only screen and (max-width: 600px) {
    .hp-navbar {
        margin-top: 30px;
        margin-right: 5px;
        max-width: 125px;
    }
    .gridText1, .gridText2,
    .gridText3, .gridText4,
    .gridText5 {
        display: none;
    }
    .numberContainer {
        bottom: 15px;
    }
    .numberItem img {
        height: 20px;
    }
    .numberItem .numberValue {
        font-size: 20px;
    }
    .numberItem .numberDescription {
        font-size: 10px;
    }
}

/* XXS devices (phones up to 400px) */
@media only screen and (max-width: 400px) {
    .btnMovie, .btnNew, 
    .btnProject, .btnAtlas,
    .slogan {
        font-size: 13px;
    }
    .fadeInText {
        font-size: 12px;
    }
    .numberItem {
        width: 75px;
    }
    .numberDescription {
        display: none;
    }
}

@media only screen and (max-height: 520px) {
    .numberDescription {
        display: none;
    }
}

/* Landscape mode on mobile devices (max-height: 410px) */
@media only screen and (max-height: 410px) {
    .App {
        height: unset;
    }
    .gridText1 {
        display: none;
    }
    .numberContainer {
        display: none;
    }
}
.ordersPage {
  font-family: "Inter", "Source Sans Pro", sans-serif !important;
}

.ordersContent {
  position: relative;
  margin: 50px 200px;
  min-height: 600px;
}

.myOrders .h3Text {
  font-weight: bold;
}

.ordersNumber {
  background-color: rgba(0, 134, 142, 0.4);
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  color: #00868e;
}

.ordersTable {
  margin-top: 50px;
}

.tableHead th {
  font-size: 16px;
  font-weight: bold;
  color: #a1a1a1;
}

.movieRow td {
  font-size: 16px;
  color: #3e3e3e;
  font-weight: bold;
}

.movieCell label a {
  color: #00868E;
}

.movieCell label a:hover {
  text-decoration: none;
}

.movieCell {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.movieCell label {
  margin: 0;
}

.movieCell label:last-child {
  color: #a1a1a1;
  font-size: 14px;
  font-weight: 400;
}

.rightsMsg {
  font-weight: 400;
  color: #ff4646;
}

.orderBtn {
  font-weight: 400;
  font-size: 15px;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #00868e;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.orderBtn:hover {
  background-color: rgba(0, 134, 142, 0.8);
}

.paginationBtns {
  position: absolute;
  bottom: -60px;
  right: 0;
  list-style: none;
  padding: 0;
  display: flex;
  width: fit-content;
  gap: 20px;
}

.controllersBtn {
  color: white !important;
  background-color: #00868e;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 400;
}

.controllersBtn:hover {
  text-decoration: none;
  color: white;
}

.controllersBtn:focus {
  outline: none;
  color: white;
  text-decoration: none;
}

.paginationDisabled {
  background-color: red;
}

.paginationBtns li {
  font-size: 16px;
  font-weight: bold;
}

.paginationBtns li a:focus {
  outline: none;
}

.paginationBtns li a {
  color: #a1a1a1;
  text-decoration: none;
}

.paginationBtn li a:hover,
.paginationBtn li a:focus {
  color: white;
}

.activePagination a {
  color: #00868e !important;
}

.paginationDisabled {
  opacity: 0.3;
}

.orderColumn {
  width: 200px;
}

@media only screen and (max-width: 1366px) {
  .ordersContent {
    margin: 50px 100px;
  }

  .orderColumn {
    width: 166px;
  }
}

@media only screen and (max-width: 1050px) {
  .ordersContent {
    margin: 50px 20px;
  }
}

@media only screen and (max-width: 480px) {
  .tableHead th,
  .movieRow td,
  .orderBtn {
    font-size: 14px;
  }

  .movieCell label:last-child {
    font-size: 12px;
  }
  
  .paginationBtns {
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .orderColumn {
    width: 110px;
  }
}

.exerciseA2Container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 80px;
    margin-right: 80px;
}

.exerciseA2Container>*:not(:first-child) {
    margin-left: 75px;
}

.textExerciseA2 {
    white-space: pre-line;
    height: 350px;
    overflow: auto;
    line-height: 1.3;
    padding-right: 15px;
}

.imageExerciseA2 {
    height: 200px;
    width: 305px;
    border-radius: 3%;
    object-fit: cover;
}
 
.playRectangle {
    width: 305px;
    height: 30px;
    margin-top: 5%;
    border-radius: 20px;
    background-color: #00868E;
    display: flex;
    cursor: pointer;
}

.audio {
    height: 30px;
    width: 305px;
    opacity: 0.75;
    border-radius: 20px;
}

@media only screen and (max-width: 1270px) {
    .exerciseA2Container {
        margin-left: 60px;
        margin-right: 60px;
    }

    .textExerciseA2 {
        height: 300px;
    }

    .imageExerciseA2 {
        width: 285px;
        height: 180px;
    }

    .playRectangle {
        width: 285px;
    }
}


@media only screen and (max-width: 1100px) {
    .exerciseA2Container {
        margin-left: 50px;
        margin-right: 50px;
    }

    .exerciseA2Container>*:not(:first-child) {
        margin-left: 40px;
    }

    .textExerciseA2 {
        width: 325px;
        height: 275px;
    }

    .imageExerciseA2 {
        width: 255px;
        height: 150px;
    }

    .playRectangle {
        width: 255px;
    }
}
.exerciseB2Container {
    display: flex;
    margin-top: 40px;
    margin-left: 80px;
    margin-right: 80px;
}

.exerciseB2Container > *:not(:first-child) {
    margin-left: 50px;
}

.imageArrowsStructure {
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.imageArrowsStructure > *:not(:first-child) {
    margin-left: 50px;
}

.exB2Slider {
    display: flex;
    flex-direction: column;
}

.exB2ImgContainer {
    text-align: center;
    height: 300px; 
    width: 250px;
}

.imageExerciseB2 {
    max-height: 100%; 
    max-width: 100%; 
    border-radius: 2%;
}

.scrollBar {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 300px;
}

.textExerciseB2 {
    text-align: justify;
    line-height: 1.2em;
    white-space: pre-wrap;
    padding-right: 15px;
}

.prevPoster,
.nextPoster {
    cursor: pointer;
    height: 18px;
    opacity: 0.7;
}

.prevPoster:hover,
.nextPoster:hover {
    opacity: 1;
}

@media only screen and (max-width: 1270px) {
    .exerciseB2Container > *:not(:first-child) {
        margin-left: 30px;
    }

    .exB2ImgContainer {
        height: 260px;
        width: 210px;
    }

    .scrollBar {
        height: 260px;
    }
}

@media only screen and (max-width: 1100px) {
    .exerciseB2Container {
        margin-top: 20px;
        margin-left: 60px;
        margin-right: 60px;
    }
}
@import "~react-image-gallery/styles/css/image-gallery.css";

/* D-DIN Regular - 400 */
@font-face {
    font-family: 'D-DIN';
    src: url('./assets/fonts/D-DIN.woff') format('woff'), url('./assets/fonts/D-DIN.woff') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* D-DIN Bold - 500 */
@font-face {
    font-family: 'D-DIN';
    src: url('./assets/fonts/D-DIN-Bold.woff') format('woff'), url('./assets/fonts/D-DIN-Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

/* D-DIN Exp Regular - 400 */
@font-face {
    font-family: 'D-DIN Exp';
    src: url('./assets/fonts/D-DINExp.woff') format('woff'), url('./assets/fonts/D-DINExp.woff') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* D-DIN Exp Bold - 500 */
@font-face {
    font-family: 'D-DIN Exp';
    src: url('./assets/fonts/D-DINExp-Bold.woff') format('woff'), url('./assets/fonts/D-DINExp-Bold.woff') format('truetype');
    font-weight: 500;
    font-style: normal;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Inter', Bahnschrift, 'Source Sans Pro', 'D-DIN', sans-serif, -apple-system, BlinkMacSystemFont;
    letter-spacing: .2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    position: relative;
}

.cookie-container {
    background: #BDE0E2 !important;
    color: black !important;
    justify-content: center !important;
}

.cookie-txt {
    font-size: 14px;
    font-weight: 300;
}  

/** Global modal styling **/
.modalHeader,
.modalBody {
    position: relative;
    padding: 15px;
}

.modalHeader {
    border-bottom: 1px solid #e5e5e5;
}

.modalHeader .close {
    top: 50%;
    right: 10px;
    position: absolute;
    transform: translate(-50%, -35%);
}

.close {
    font-size: 33px;
}

.modalFooter {
    display: flex;
    justify-content: center;
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgb(253, 253, 253);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgb(253, 253, 253);
}

/* Hide/show content */
.inactive-arrows {
    visibility: hidden;
}

.active-arrows {
    visibility: visible;
}

.h1Text {
    font-size: 45px;
}

.h2Text {
    font-size: 35px;
}

.h3Text {
    font-size: 30px;
}

.h4Text {
    font-size: 24px;
}

.h5Text {
    font-size: 22px;
}

.bigText {
    font-size: 19px;
}

.normalText {
    font-size: 16px;
}

.smallText {
    font-size: 14px;
}

.detailsText {
    font-size: 14px;
}

.boldTxt {
    font-weight: bold;
}

@media only screen and (max-width: 1500px) {
    .h1Text {
        font-size: 40px;
    }

    .h2Text {
        font-size: 31px;
    }

    .h3Text {
        font-size: 28px;
    }
}

@media only screen and (max-width: 1280px) {
    .h2Text {
        font-size: 26px;
    }

    .normalText {
        font-size: 15px;
    }
    
    .detailsText {
        font-size: 13.5px;
    }
}


@media only screen and (max-width: 1170px) {
    .h2Text {
        font-size: 24px;
    }

    .h3Text {
        font-size: 25px;
    }

    .h4Text {
        font-size: 20px;
    }

    .h5Text {
        font-size: 19px;
    }

}

@media only screen and (max-width: 991px) {
    .h1Text {
        font-size: 35px;
    }

    .h2Text {
        font-size: 20px;
    }

    .h3Text {
        font-size: 20px;
    }
    
    .h4Text,
    .h5Text {
        font-size: 18px;
    }
    
    .bigText {
        font-size: 18px;
    }
}

@media only screen and (max-width: 660px) {
    .cookie-txt {
        flex: unset !important;
        text-align: center;
        margin-bottom: 0px !important;
    }

    .cookie-txt,
    #rcc-confirm-button {
        font-size: 12px !important;
    }


    .h1Text {
        font-size: 32px;
    }

    .normalText {
        font-size: 14px;
    }

    .smallText,
    .detailsText {
        font-size: 13px;
    }
}

@media only screen and (max-width: 470px) {
    .h1Text {
        font-size: 26px;
    }
}

@media only screen and (max-width: 400px) {
    .bigText {
        font-size: 15px;
    }
}
.photoVideoWrapper {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.photoVideoWrapper > div:first-child {
  margin-right: 80px;
}

.galleryPhotosStructure {
  width: 60%;
}

.galleryPhotosTitle {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.gridPhoto {
  display: grid;
  row-gap: 20px;
  gap: 10px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.photoGallery,
.gridPhoto .EnlargedImage__Image {
  max-width: 100%;
  width: 180px;
  height: 90px !important;
  filter: grayscale(20%);
  object-fit: cover;
}

.videoPlay {
  max-width: 100%;
  margin: 0 auto;
  cursor: pointer;
}
.video-player-container {
  position: relative;
  background: none;
  border: none;
}
.play-icon {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pswp img {
  max-width: none;
  object-fit: contain;
}

.imagesGallery {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 56.25%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

.imagesGallery:hover {
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .galleryPhotosStructure {
    width: 70%;
    margin-top: 50px;
  }

  .photoVideoWrapper {
    padding-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .photoVideoWrapper > div:first-child {
    margin-right: 0px;
  }
  
  .gridPhoto {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 470px) {
  .galleryPhotosStructure {
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .gridPhoto {
    display: grid;
    grid-template-columns: auto;
  }
}

@media only screen and (max-width: 350px) {
  .gridPhoto {
    display: grid;
    grid-template-columns: auto;
    row-gap: 20px;
    gap: 10px;
  }
}

.shareBoxForm {
    position: relative;
    height: 490px;
    width: 1000px;
    background-color: transparent;
    box-shadow: 0 4px 8px 0 #00868E, 0 0px 6px 0 #00868E;
    border-radius: 2% 2% 2% 2%;
    margin-top: 50px; 
    margin-left: auto;
    margin-right: auto;
}

.shareWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.shareWrapper > * {
    margin-left: 22px;
    margin-right: 22px;
}

.posterSharePage {
    height: 400px;
    max-width: 300px;
    object-fit: cover;
    object-position: left;
}

.shareText {
    color: #D4AF37;
    font-size: 32px;
    line-height: 1.3em;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
}

.shareCodeForm {
    height: 35px;
    width: 170px;
    background-color: transparent;
    border: 1.5px solid #D4AF37;
    box-shadow:  0 4px 8px 0 #D4AF37, 0 0px 6px 0 #D4AF37;
    border-radius: 2%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 45px;
}

.textCode {
    color: black;
    font-size: 18px;
    margin-top: 3px;
    text-align: center;
}

.instructions {
    color: #684B1A;
    font-size: 15px;
    margin-top: 12px;
    text-align: center;
}
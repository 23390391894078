.content {
    margin: auto;
}

.pageContainer {
    width: 1200px;
    margin-top: 75px;
}

.projectDescTitle,
.projectTitle {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.projectDescTitle {
    margin-bottom: 15px;
    margin-top: 50px;
}

.projectTitle {
    margin-top: 75px;
    margin-bottom: 15px;
}

.projectText {
    text-align: center;
    color: black;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectItem {
    margin-bottom: 0;
}

.listProjectIcon {
    font-size: 1.6rem !important;
    margin-right: 4px;
}

.textBeforeTestimonials {
    margin-top: 50px;
    margin-bottom: 25px;
}

.readMoreMargin {
    margin-top: 25px;
    margin-bottom: 25px;
}

.collapsibleDiv {
    transform-origin: top;
    transition: transform 0.26s ease;
}

.contentMargin {
    margin-top: 25px;
}

.tableTitle {
    margin-top: 25px;
    color: #00868E;
    font-weight: 800;
    text-transform: uppercase;
}

.sectionClosing {
    margin-top: 25px;
}

.whitebookSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cinedBtn,
.cinedLargeBtn {
    margin-top: 10px;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    letter-spacing: .2px;
    line-height: 1;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
}

.cinedBtn {
    padding: 45px 8% 40px;
}

.cinedLargeBtn {
    padding: 45px 12% 40px;
}

.cinedBtn::before,
.cinedLargeBtn::before {
    background-image: url('../../assets/images/cinedLogoBkg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.cinedBtn:hover, .cinedLargeBtn:hover,
.cinedBtn:focus, .cinedLargeBtn:focus {
    text-decoration: none;
    color: #fff;
}

.cinedBtn span,
.cinedLargeBtn span {
    position: relative;
}

.gridLogos {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 50px;
    text-align: center;
    justify-content: center;
}

.projectLogo {
    max-height: 120px;
    max-width: 120px;
}

.pointer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 2px solid #00868e;
}

.pointer:hover {
    cursor: pointer;
    opacity: 0.5;
}

.sectionIntro {
    margin-bottom: 30px;
}

@media only screen and (max-width: 1500px) {
    .pageContainer {
        width: 1000px;
    }

    .gridLogos {
        grid-template-columns: auto auto auto auto;
    }

}

@media only screen and (max-width: 1280px) {
    .pageContainer {
        width: 85%;
    }
}

@media only screen and (max-width: 1170px) {
    .pageContainer {
        width: 90%;
    }
}

@media only screen and (max-width: 991px) {
    .pageContainer {
        margin-top: 50px;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .cinedBtn {
        padding: 45px 12% 40px;
    }

    .gridLogos {
        grid-template-columns: auto auto auto;
    }
}


@media only screen and (max-width: 764px) {
    .gridLogos {
        display: grid;
        grid-template-columns: auto auto;
        gap: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .cinedBtn {
        width: 80%;
    }

    .projectLogo {
        max-height: 85px;
        max-width: 85px;
    }

    .pointer {
        width: 125px;
        height: 125px;
    }
}

@media only screen and (max-width: 470px) {
    .pageContainer {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .cinedBtn {
        width: 85%;
    }

    .gridLogos {
        grid-template-columns: auto auto;
        gap: 15px;
    }
}

@media only screen and (max-width: 400px) {
    .projectLogo {
        max-height: 75px;
        max-width: 75px;
    }

    .pointer {
        width: 100px;
        height: 100px;
    }
}
.authInput,
.inputRegister,
.dropdownRegister {
    width: 200px;
    height: 30px;
    background-color: transparent;
    border: 1px solid transparent;
    /*border-radius: 10px;*/
    outline-color: rgb(218, 214, 161);
    margin-top: 11px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.2px;
}

.registerInputsWrapper .authColumn {
    width: 200px;
}

.authInput,
.inputRegister {
    color: rgb(57, 11, 110);
    resize: none;
}

input[type="date"],
.dropdownRegister {
    color: gray;
}

.passwordRegisterWrappper {
    position: relative;
    width: 200px;
}

.submitRegistration {
    cursor: pointer;
    border-radius: 20px;
    background-color: #00868E;
    text-align: center;
    border: 1px solid #00868E;
    color: white;
    padding: 8px 15px;
    opacity: 0.7;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 15px;
}

.submitRegistration[disabled] {
    background-color: gray !important;
    border: 1px solid gray;
}

.submitRegistration.forgotPass {
    margin-top: 0 !important;
    margin-bottom: 15px;
}

.submitRegistration:hover {
    background-color: #D8B74B;
    border: 1px solid #D8B74B;
    color: white;
    opacity: 1;
}

.submitRegistration[disabled]:hover {
    background-color: gray !important;
    border: 1px solid gray;
}

.submitRegistration:active {
    box-shadow: 0 3px rgb(218, 214, 161);
    transform: translateY(2px);
}

.submitRegistration[disabled]:active {
    box-shadow: unset;
    transform: unset;
}

/* Forget password modal */
.submitUsernameWrapper {
    display: flex;
}

.submitUsernameWrapper .inputRegister {
    border-bottom: 2px solid #D8B74B;
    border-radius: 0;
}

.submitUsername {
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    font-weight: bold;
    border: none;
    color: #00868E;
    display: block;
    margin-bottom: 5px;
    margin-top: 14px;
    text-decoration: none;
}

.signInModalTitle {
    font-weight: bold;
    text-align: center;
    color: #00868E;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
}

.registerInputsWrapper {
    display: flex;
    justify-content: center;
}

.registerInputsWrapper>div:not(:first-child) {
    margin-left: 60px;
}

.fieldsDivision {
    border-top: 2px solid #D8B74B;
    width: 200px;
    opacity: 0.58;
    margin-top: -2px;
    margin-bottom: 4px;
}

.eyeSignUp {
    position: absolute;
    right: 2px;
    bottom: 3px;
}

.eyeSignUp:hover {
    color: #D8B74B;
    cursor: pointer;
}

/* Small devices devices (portrait tablets and large phones up to 991px) */
@media only screen and (max-width: 991px) {
    .signInModalTitle {
        max-width: 200px;
    }
    .registerInputsWrapper {
        margin-top: 10px;
        flex-direction: column;
        align-items: center;
    }
    /*.registerInputsWrapper input,
    .passwordRegisterWrappper input,
    .registerInputsWrapper select {
        margin-top: 12px;
    }*/
    .registerInputsWrapper>div:not(:first-child) {
        margin-left: unset;
    }
    .registerInputsWrapper>div,
    .passwordRegisterWrappper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Small devices devices (portrait tablets and large phones up to 768px) */
@media only screen and (max-width: 768px) {
    .signInModalTitle {
        max-width: 200px;
    }
}

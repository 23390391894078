.centeredContainer.column {
    flex-direction: column;
}

.modalMaxWidth {
    max-width: 800px;
    margin: auto;
}

.playerLinkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.descriptionContainer {
    margin: 20px 0;
}

.modalCloseBtn button {
    border: 1px solid #00868e;
    background-color: white;
    color: #00868e;
    width: 100px;
}

.modalCloseBtn button:focus,
.modalCloseBtn button:active {
    background-color: #006469 !important;
    color: white !important;
    outline: none !important;
    border: 1px solid #006469 !important;
}

.modalCloseBtn button:hover {
    border: 1px solid #00868e;
    background-color: #00868e;
    color: white;
}

.modalCloseBtn button p {
    margin: 0;
}

.playerLink {
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 5px;
    height: 46px;
    width: 300px;
    border-radius: 5px;
    text-transform: uppercase;
    background: linear-gradient(-90deg, #00868e, #48d9e0, #00868e);
    background-size: 400% 400%;
    animation: gradient 6s ease infinite;
}

.playerLink span {
    margin-right: 15px;
}

.playerLink:hover {
    background: linear-gradient(-90deg, #f49800, #ffcb5a, #f49800);
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;
}

.playerLinkFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.playerLinkFooter svg {
    width: 15px;
    height: 100%;
    margin-left: 10px;
}

.playerLinkContainer svg:nth-child(1):hover path {
    fill: #f49800;
}

.playerLinkFooter p {
    margin: 0;
}

.PopupContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 10px 0;
}

.PopupContainer a {
    color: #00868e;
    cursor: pointer;
}

.modalButton.warningBtn {
    margin-top: 10px;
    padding: 15px;
    text-transform: uppercase;
}

.MRcontainer {
    width: 500px;
}

.MRcontainer .MRheader {
    width: 100%;
    background-color: #00868e;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MRheader .iconContainer {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MRcontent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    flex-direction: column;
}

.MRcontent .descriptionTitle h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    background: linear-gradient(-90deg, #000000, #5bf7ff, #000000);
    background-size: 600% 600%;
    animation: gradient 6s ease infinite;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.MRcontent .descriptionTitle {
    margin-bottom: 20px;
}

.MRcontent .descriptionContent .row {
    display: flex;
}

.MRcontent .descriptionContent .row path {
    fill: #00868e;
}

.MRcontent .descriptionContent .row svg {
    width: 22px;
    height: 18px;
    margin-right: 10px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@-webkit-keyframes gradient {
    0% {
        -webkit-background-position: 0% 50%;
    }
    50% {
        -webkit-background-position: 100% 50%;
    }
    100% {
        -webkit-background-position: 0% 50%;
    }
}

@media only screen and (max-width: 600px) {
    .playerLink {
        width: unset;
        padding: 10px;
    }

    .MRcontainer {
        width: unset;
        min-width: 320px;
    }
}

@media only screen and (max-width: 380px) {
    .MRcontainer {
        min-width: 240px;
    }
}

@media only screen and (max-width: 350px) {
    .playerLink {
        font-size: 12px;
    }

    .playerLink span {
        margin-right: 10px;
    }
}

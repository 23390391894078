.exerciseA3Container {
    width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.imageDescriptionWrapper {
    width: 100%;
    display: flex;
    margin-top: 25px;
}

.imageDescriptionWrapper > *:not(:first-child) {
    margin-left: 20px;
}

.imageExerciseA3 {
    height: 200px;
    max-width: 400px;
    object-fit: cover;
    border-radius: 2%; 
} 
 
.nameExerciseA3 {
    font-weight: bold;
    font-size: 20px;
    color: #00868E;
    margin-top: 15px;
}

.scrollBarWrapper {
    width: 100%;
    display: flex;
}

.scrollBarA3 {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 128px;
    margin-top: 25px;
    padding-right: 15px;
}

.textExerciseA3 {
    white-space: pre-line;
}

@media only screen and (max-width: 1270px) {
    .imageExerciseA3 {
        height: 150px;
        max-width: 320px;
    } 

}

@media only screen and (max-width: 1100px) {
    .exerciseA3Container {
        width: 500px;
    }

    .imageExerciseA3 {
        height: 130px;
        max-width: 230px;
    } 

    .nameExerciseA3 {
        font-size: 18px;
    }
}

.mainFooter {
    background-color: #BDE0E2;
    color: white;
    text-align: center;
    margin-top: 95px;
    height: 200px;
    display: flex;
    justify-content: center;
}

.footerFirstColumn {
    display: flex;
    justify-content: center;
}

.footerLogoContainer,
.footerAboutContainer,
.footerSecondColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.footerLogoContainer,
.footerAboutContainer {
    margin-right: 80px;
}

.footerLogoContainer a {
    margin-top: 10px;
}

.facebookSentence {
    color: #391D69;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .2px;
    max-width: 235px;
}

.facebookSentence:hover {
    text-decoration: none;
}

.footerAbout {
    color: black;
    font-size: 13px;
    width: 300px;
    text-align: justify;
    line-height: 1.3em;
}

.secondaryFooterContainer {
    background-color: #f0f0f0;
}

.secondaryFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: black;
    padding: 1em;
    font-size: 13px;
}

.footerEurope {
    margin-right: 15px;
    height: 40px;
}

.footerText {
    color: #333333;
    letter-spacing: .1px;
    text-align: center;
    max-width: 838px;
}

.footerText p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.footerLink {
    margin: 3px;
}

@media only screen and (max-width: 1450px) {
    .footerLogoContainer,
    .footerAboutContainer {
        margin-right: 50px;
    }
}

@media only screen and (max-width: 1280px) {
    .footerLogoContainer,
    .footerAboutContainer {
        margin-right: 30px;
    }
    .facebookSentence {
        font-size: 14px;
    }
    .footerSecondColumn img {
        max-height: 130px;
        height: 100%;
        width: auto;
    }
    .secondaryFooter {
        max-width: 80%;
        margin: 0 auto;
    }
}


/* Large devices (up to 1100px) */
@media only screen and (max-width: 1100px) {
    .mainFooter {
        height: unset;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .footerFirstColumn {
        margin-top: 20px;
    }
    .footerLogoContainer {
        margin-right: 80px;
    }
    .footerAboutContainer {
        margin-right: 0;
    }
    .footerSecondColumn {
        margin-top: 20px;
    }
    .footerSecondColumn img {
        max-height: unset;
        height: unset;
        width: 450px;
    }
}

@media only screen and (max-width: 764px) {
    .footerLogoContainer {
        margin-right: 30px;
    }
    .facebookSentence {
        max-width: 200px;
    }
    .footerSecondColumn img {
        width: 75%;
        margin: 0 auto;
    }
    .secondaryFooter {
        max-width: 95%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 600px) {
    .footerFirstColumn {
        flex-direction: column;
    }
    .footerLogoContainer a {
        padding-top: 10px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
    }
    .facebookSentence {
        margin-top: 10px;
        padding-left: 5px;
        max-width: 60%;
    }
    .footerAboutContainer {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .footerAbout {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .footerSecondColumn {
        margin-top: 0;
    }
    .footerSecondColumn img {
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 520px) {
    .secondaryFooter {
        flex-direction: column;
    }
    .footerEurope {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .footerAbout,
    .secondaryFooter {
        font-size: 12px;
    }
}
.authForm {
    background-color: #F7F6F6;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 2% 2% 2% 2%;
    padding-right: 40px;
    padding-left: 40px;
}

.signInForm {
    width: 700px;
    padding-top: 45px;
    padding-bottom: 45px;
}

.signInFormWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signInStudentTeacher {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signInStudentTitle {
    color: #00868E;
    font-weight: bold;
    text-align: center;
}

.authInput.gap {
    margin-top: 8%;
}

.registrationTitle {
    font-weight: bold;
    color: #D8B74B;
    text-align: center;
    margin-top: 20px;
}

.signUpForm {
    width: 1150px;
    padding-top: 45px;
    padding-bottom: 30px;
}

.signUpFormWrapper {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.signUpFormWrapper>div:not(:first-child) {
    margin-left: 5%;
}

.wrapperTeacher {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.wrapperTeacher > * {
    margin-right: 20px;
}

.signUpStudentTeacher {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.signUpTitle {
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.3em;
    font-weight: bold;
}

.signUpStudentTitle {
    color: #00868E;
}

.signUpTeacherTitle {
    color: #D8B74B;
}

.signUpDivision {
    border-left: 2px solid #D8B74B;
    height: 150px;
    margin-top: 1%;
    margin-bottom: 1%;
    opacity: 0.58;
}

.signUpRoleWrapper {
    margin-top: 8px;
}

.signUpRole {
    color: #D8B74B;
    font-weight: bold;
}

.buttonSignUpWrapper {
    display: flex;
    justify-content: center;
}

.btnBackWrapper {
    margin-top: 15px;
}

.btnBackLink:hover {
    text-decoration: none;
}

.btnRegistration {
    cursor: pointer;
    border-radius: 20px;
    background-color: #D8B74B;
    color: white;
    text-align: center;
    border: 1px solid #D8B74B;
    padding: 8px 20px;
    opacity: 0.7;
    display: block;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
}

.btnRegistrationLink:hover {
    text-decoration: none;
}

.btnRegistration:hover {
    background-color: #D8B74B;
    border: 1px solid #D8B74B;
    color: white;
    opacity: 1;
}

.error,
.success {
    font-size: 12px;
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
}

.error {
    color: red;
}

.success {
    color: green;
}

@media only screen and (max-width: 1270px) {
    .signUpForm {
        width: 90%;
    }
}

/* Small devices devices (portrait tablets and large phones up to 991px) */
@media only screen and (max-width: 991px) {
    .authForm {
        padding-right: 5px;
        padding-left: 5px;
    }
    .signInForm,
    .signUpForm {
        top: calc(50% + 30px);
        width: 80%;
    }
    .signUpFormWrapper {
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
    }
    .signUpStudentTeacher {
        width: 100%;
    }
    .signUpTitle {
        height: unset;
    }
    .signUpDivision {
        height: unset;
        border-left: unset;
        border-bottom: 2px solid #D8B74B;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .signUpFormWrapper>div:not(:first-child) {
        margin-left: unset;
    }
    .btnBackWrapper {
        margin-top: 25px;
    }
    .wrapperTeacher {
        margin-left: auto;
        margin-right: auto;
    }
    .buttonSignUpWrapper {
        margin-top: 0%;
    }
    .signUpTeacherTitle{
        margin-top: 20%;
    }
}


/* Small devices devices (portrait tablets and large phones up to 768px) */
@media only screen and (max-width: 768px) {
    .signInForm,
    .signUpForm {
        top: calc(50% + 30px);
        width: 80%;
    }
    .signUpFormWrapper {
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
    }
    .signUpStudentTeacher {
        width: 100%;
    }
    .signUpTitle {
        height: unset;
    }
    .signUpDivision {
        height: unset;
        border-left: unset;
        border-bottom: 2px solid #D8B74B;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .signUpFormWrapper>div:not(:first-child) {
        margin-left: unset;
    }
    .btnBackWrapper {
        margin-top: 25px;
    }
    .registerInputsWrapper {
        margin-top: 10px;
        flex-direction: column;
        padding-left: 25px;
        padding-right: 25px;
    }
    /*.registerInputsWrapper input,
    .passwordRegisterWrappper input,
    .registerInputsWrapper select {
        margin-top: 12px;
    }*/
    .registerInputsWrapper>div:not(:first-child) {
        margin-left: unset;
    }
    .registerInputsWrapper>div,
    .passwordRegisterWrappper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .wrapperTeacher{
        margin-left: auto;
        margin-right: auto;
    }
    .buttonSignUpWrapper{
        margin-top: 0%;
    }
    .signUpTeacherTitle{
        margin-top: 20%;
    }
}

/* XXS devices (phones up to 400px) */
@media only screen and (max-width: 400px) {
    .signInForm,
    .signUpForm {
        width: 90%;
    }
}

@media only screen and (max-height: 550px) {
    .signInUpPlaceholder {
        height: 30px;
    }
    .signInForm,
    .signUpForm {
        position: relative;
        top: unset;
        left: unset;
        transform: none;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}
.exerciseB1Container {
    margin-top: 40px;
    margin-left: 80px;
    margin-right: 80px;
}

.titleFlagStructure {
    display: flex;
}

.titleFlagStructure .movieB1 {
    margin-right: 10px;
}

.movieB1 {
    font-size: 16px;
    line-height: 1.2em;
    color: #684B1A;
}

.flagB1 {
    height: 20px; 
    z-index: 1;
}

.translationsStructure {
    font-size: 13px;
    color: rgb(78, 56, 18); 
}

.translationMapWrapper p {
    line-height: 1.1em;
}

.titleTranslations {
    width: 320px;
    margin-right: 10px;
    text-align: justify;
    z-index: 3;
}

.mapB1 {
    height: 420px;
    position: absolute;
    right: 0;
    bottom: 8px;
    z-index: -1;
}

@media only screen and (max-width: 1270px) {
    .exerciseB1Container {
        margin-top: 20px;
        margin-left: 60px;
        margin-right: 60px;
    }
    
    .translationsStructure {
        font-size: 12px;
    }

    .titleTranslations {
        width: 300px;
    }

    .mapB1 {
        height: 375px;
    }
}

@media only screen and (max-width: 1100px) {
    .exerciseB1Container {
        margin-left: 50px;
        margin-right: 50px;
    }

    .translationMapWrapper p {
        line-height: 1em;
    }

    .flagB1 {
        height: 18px; 
    }

    .titleTranslations{
        width: 280px;
    }

    .mapB1 {
        height: 300px;
        bottom: 35px;
    }
}

.swal2-icon {
    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 92px;
    height: 92px;
    border: 4px solid transparent;
    border-radius: 50%;
    border-color: #000;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    margin: 20px auto;
}

.swal2-icon.swal2-warning {
    border-color: #facea8;
    color: #f8bb86;
}

.swal2-icon .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: 68px;
}
.exerciseContainer.exC21 >*:not(:first-child) {
    margin-left: 30px;
}

.imageExerciseEx21,
.uploadFileBox.exC21 {
    height: 290px;
    width: 210px;
}

.imageUploadedEx21 {
    max-height: 280px;
    max-width: 200px;
}

@media only screen and (max-width: 1270px) {
    .imageExerciseEx21,
    .uploadFileBox.exC21 {
        height: 270px;
    }

    .imageUploadedEx21 {
        max-height: 260px;
    }
}

@media only screen and (max-width: 1100px) {
    .imageExerciseEx21,
    .uploadFileBox.exC21 {
        height: 250px;
    }

    .imageUploadedEx21 {
        max-height: 240px;
    }
}
.modalTitleTD {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.modalSection {
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    width: 400px;
}

.modalSectionLabel {
    margin-top: 0%;
    width: 200px;
    font-weight: bold;
    line-height: 1.2em;
}

.modalSectionInfo {
    color: rgb(83, 60, 21);
    width: 320px;
    line-height: 1.2em;
}

.separatorModal {
    border-top: 2px solid #00868E;
    width: 598px;
}

.widthLabel {
    margin-top: 8px;
}

.widthInfo {
    margin-top: 8px;
}

.otherMovies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.setMovies {
    display: flex;
    justify-content: center;
}

.figureStructureRelatedMovie {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 60px;
    gap: 40px;
    row-gap: 40px;
}

@media only screen and (max-width: 991px) {
    .figureStructureRelatedMovie {
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width: 660px) {
    .movieDetails.container {
        padding: 0;
    }

    .figureStructureRelatedMovie {
        grid-template-columns: auto;
    }

    .modalTitleTD {
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .modalSection, .modalSectionInfo, .separatorModal,
    .widthLabel, .widthInfo {
        width: unset;
    }
}

@media only screen and (max-width: 470px) {
    .modalSection {
        flex-direction: column;
    }
}
/* Style movie collection */
.alignMovieCaption {
    justify-content: center;
    align-items: center;
    align-content: center;
}

.centeredContainer {
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;
}

.videoCardContainer {
    position: relative;
    border-radius: 5px;
    padding: 8px;

    --b: 3px;
    --c: #F49800;
    --w: 38px;
    --_g: #0000 90deg,var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    border: var(--b) solid #0000;
    background: 
        conic-gradient(from 90deg at top var(--b) left var(--b),var(--_g)) 0 0 / var(--_p), 
        conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
}

.videoPhotogram {
    border-radius: 5px;
    height: 200px;
    width: 300px;
    object-fit: cover;
}

.alignMovieCaption:hover .videoPhotogram,
.alignMovieCaption:hover .captionMovie {
    opacity: 0.8;
}

.dot {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 35px;
    width: 35px;
    background-color: #00868E;
    border-radius: 50%;
}

.age {
    line-height: 1.2em;
    color: white;
    text-align: center;
    margin-top: 20%;
}

.movieTitleContainer {
    margin-top: 2px;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 8px;
    text-align: center;
}

.captionMovie {
    line-height: 1.2em;
    color: black;
    font-weight: 500;
    text-decoration: none;
}

.captionMovie:hover {
    color: black;
    text-decoration: none;
}

.translatedTitle {
    margin-top: 2px;
    color: #F49800;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .2px;
}

/* Extra large devices (up to 1700px) */
@media only screen and (max-width: 1800px) {
    .videoPhotogram {
        width: 280px;
        height: 187px;
    }
}

@media only screen and (max-width: 1650px) {
    .videoPhotogram {
        width: 300px;
        height: 200px;
    }
}

@media only screen and (max-width: 1280px) {
    .videoPhotogram {
        width: 250px;
        height: 134px;
    }
}

@media only screen and (max-width: 991px) {
    .videoPhotogram {
        width: 225px;
        height: 121px;
    }
    .captionMovie,
    .translatedTitle {
        max-width: 220px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 600px) {
    .videoPhotogram {
        width: 280px;
        height: 150px;
    }
    .captionMovie {
        max-width: 280px;
    }
}

@media only screen and (max-width: 400px) {
    .videoPhotogram {
        width: 225px;
        height: 121px;
    }
    .captionMovie {
        max-width: 250px;
    }
}
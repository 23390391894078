.exerciseFlexWrapper.exD > *:not(:last-child) {
    margin-right: 50px;
}

.textStructure {
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.captionExercise {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: black;
    margin-bottom: 30px;
}

.descriptionExercise {
    white-space: pre-line;
    text-align: left;
    font-size: 14px;
    color: #684B1A;
    line-height: 1.2em;
    text-align: justify;
}

.imageExerciseDContainer {
    display: flex;
    justify-content: center;
    height: 250px;
    width: 300px;
}

.imageExerciseDContainer img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 1270px) {
    .exerciseFlexWrapper.exD > *:not(:last-child) {
        margin-right: 25px;
    }

    .textStructure {
        width: 350px;
    }

    .captionExercise {
        font-size: 15px;
    }

    .descriptionExercise {
        font-size: 13px;
    }

    .imageExerciseDContainer {
        height: 290px;
        width: 250px;
    }
}

@media only screen and (max-width: 1100px) {
    .captionExercise {
        font-size: 14px;
    }
    
    .textStructure {
        width: 300px;
    }

    .imageExerciseDContainer {
        height: 250px;
        width: 225px;
    }
}
.headerMovieStructure,
.headerMovieImg,
.headerFilter {
    height: calc(100vh - 82px);
    min-height: 750px;
}

.headerMovieStructure {
    position: relative;
    display: flex;
    background-color: rgb(160, 160, 160);
    justify-content: center;
    justify-items: center;
}

.headerMovieImg {
    width: 100%;
    object-fit: cover;
    object-position: top;
    filter: blur(3px);
    position: absolute;
}

.headerFilter {
    position: absolute;
    width: 100%;
    background: black;
    top: 0;
    opacity: 0.5;
}

.headerContent {
    position: relative;
    display: flex;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-left: 55px;
    margin-right: 55px;
}

.titleInfo {
    color: white;
    line-height: 1.2em;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 700;
}

.headerTranslatedTitle {
    color: white;
    text-transform: uppercase;
    line-height: 1.2em;
    margin: 0;
}

.wrapperDetails {
    display: flex;
    margin-top: 20px;
}

.wrapperDetails>* {
    margin-bottom: 5px;
    padding-right: 30px;
}

.rightsInfo {
    font-weight: bold;
    color: #D8B74B;
}

.detailsInfo {
    color: white;
}

.movieContentWrapper {
    width: 70%;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapperButtonsInfo {
    margin-top: 20px;
}

.requestContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
}

.loadingRequest {
    margin-top: 15px;
}

.loadingRequestMobile {
    width: 100%;
    display: none;
}

.wrapperButtonsFiles.flex {
    display: flex;
}

.wrapperButtonsFiles.grid {
    display: grid;
    grid-template-columns: auto auto;
}

.wrapperButtonsFiles.grid .buttonDetails {
    width: 100%;
}

.wrapperButtonsInfo>*, .wrapperButtonsFiles>* {
    margin-right: 20px;
}

.synopsisInfo {
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: justify;
    line-height: 1.4em;
}

.educationalFile {
    color: #D4AF37;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 1px 1px black;
    letter-spacing: .1px;
}

.educationalFile:hover,
.educationalFile:focus {
    color: #D4AF37;
    opacity: 0.8;
}

.orderButton,
.buttonDetails {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #D8B74B;
    text-decoration: none;
    color: white;
    text-align: center;
}

.txtDetails {
    color: white;
    margin-bottom: 10px;
    padding: 5px 10px;
}

.txtDetails .doneIcon {
    color: white;
    width: 18px !important;
    height: 18px !important;
    vertical-align: middle;
}

.orderButton {
    border-radius: 5px;
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 15px;
    opacity: 0.7;
    width: 200px;
}

.buttonDetails {
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 10px;
}

.buttonDetails.mobileOnly,
.txtDetails.mobileOnly {
    display: none;
}

.buttonDetails:hover {
    border: 1px solid #D4AF37;
    color: white;
    background-color: #D4AF37;
}

.orderButton:hover {
    background-color: #D8B74B;
    border: 1px solid #D8B74B;
    opacity: 1;
}

.orderButton:active {
    box-shadow: 0 3px rgb(218, 214, 161);
    transform: translateY(2px);
}

.playContainer {
    position: relative;
    display: flex;
    flex-direction: column;
}

.requestContainer.cursor:hover,
.playContainer.cursor:hover {
    cursor: pointer;
    opacity: 0.8;
}

.playContainer:hover,
.playContainer:active {
    text-decoration: none;
}

.playIcon {
    height: 210px;
}

.playDisableIcon {
    height: 210px;
}
.playDisableIconNotSupportedModal {
    height: 150px;
}

.orderTxt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    line-height: 1.2em;
    text-shadow: 1px 1px black;
    margin-top: 10px;
    margin-bottom: 10px;
}

.locTxt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    line-height: 1.2em;
    text-shadow: 1px 1px black;
    margin-top: 10px;
    margin-bottom: 10px;
}

.locTxt.blue {
    color: #00868e;
}

.doneIcon {
    color: white;
    width: 25px !important;
    height: 25px !important;
    vertical-align: middle;
    filter: drop-shadow(1px 1px black);
}

.locationIcon {
    color: #00868E;
    width: 80px !important;
    height: 80px !important;
    vertical-align: middle;
    filter: drop-shadow(1px 1px black);
}

.comingSoonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.comingSoon {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px black;
    color: white;
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
}

.comingSoon .highlight {
    color: #00868E;
}

.moreDetailsSection {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.moreDetailsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.moreDetailsText {
    color: white;
    font-weight: bold;
    letter-spacing: 0.4px;
}

.moreDetailsText:hover {
    text-decoration: none;
    color: white;
}

.moreDetailsSvg img {
    margin-top: -5px;
    cursor: pointer;
    width: 18px;
}

.marginZero {
    margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
    .headerMovieStructure,
    .headerMovieImg,
    .headerFilter {
        height: 650px;
        min-height: unset;
    }

    .headerContent {
        padding-top: 55px;
        margin-right: 0;
        margin-left: 0;
    }

    .movieContentWrapper {
        justify-content: flex-start;
    }

    .buttonDetails {
        padding: 5px 10px;
    }
}

@media only screen and (max-width: 1024px) {
    #ssinteractive {
        display: none;
    }

    .headerContent {
        padding: 40px;
    }

    .playIcon,
    .playDisableIcon {
        height: 120px;
    }
}

@media only screen and (max-width: 991px) {
    .headerMovieStructure {
        margin-top: 0;
    }

    .headerMovieStructure,
    .headerMovieImg,
    .headerFilter {
        height: 700px;
    }

    .headerContent {
        width: unset;
    }

    .movieContentWrapper {
        width: 75%;
    }

    .requestContainer,
    .comingSoonContainer {
        width: 25%;
    }

    .comingSoon {
        font-size: 20px;
    }

    .titleInfo {
        line-height: 1.1em;
    }

    .wrapperDetails {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .wrapperDetails>* {
        padding-right: 20px;
    }

    .orderButton {
        width: 100%;
    }
    .comingSoonContainer img {
        height: 80px;
    }

    .comingSoonContainer .ellipsis,
    .comingSoonContainer .highlightSpace {
        display: none;
    }

    .comingSoon {
        flex-direction: column;
    }
}

@media only screen and (max-width: 800px) {
    .headerMovieStructure,
    .headerMovieImg,
    .headerFilter {
        height: 750px;
    }

    .headerMovieStructure.small,
    .headerMovieImg.small,
    .headerFilter.small {
        height: 600px;
    }

    .movieContentWrapper {
        width: 100%;
        padding-right: 0px;
    }

    .wrapperButtonsInfo {
        margin-top: 10px;
    }

    .synopsisInfo {
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1.3em;
    }

    .detailsInfo {
        color: white;
    }

    .buttonDetails.mobileOnly,
    .txtDetails.mobileOnly {
        display: block;
    }

    .loadingRequestMobile {
        display: flex;
        justify-content: center;
    }

    .requestContainer,
    .comingSoonContainer {
        display: none;
    }
    
}

@media only screen and (max-width: 470px) {
    .wrapperDetails {
        display: grid;
        grid-template-columns: auto auto;
    }

    .wrapperButtonsInfo>*, .wrapperButtonsFiles>* {
        margin-right: 10px;
    }

    .headerContent {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .headerTranslatedTitle {
        display: none;
    }

    .buttonDetails {
        padding: 8px;
    }
    
    .moreDetailsSection {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 360px) {
    .headerMovieStructure,
    .headerMovieImg,
    .headerFilter {
        height: 800px;
    }

    .headerContent {
        padding-top: 30px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .detailsInfo {
        margin-bottom: 5px;
    }

    .wrapperDetails {
        grid-template-columns: auto;
    }

    .wrapperButtonsFiles.grid {
        display: grid;
        grid-template-columns: auto;
    }

    .buttonDetails {
        padding: 6px;
    }

    .moreDetailsText {
        display: none;
    }
}
.last-update {
  color: #7A7A7A;
  padding-bottom: 10px;
}

.resourcesText.highlight {
  font-weight: bold;
}

.resourcesText.italic {
  font-style: italic;
}

.privacySubtitle {
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  font-weight: bold;
}
.sectionTitle {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: #00868E;
    text-transform: uppercase;
}

.thematicHeader {
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    width: 1200px;
}

/* Extra large devices (desktops, up to 1450px) */
@media only screen and (max-width: 1450px) {
    .thematicHeader {
        width: 75%;
    }
}

/* Extra large devices (large desktops, up to 1450px) */
@media only screen and (max-width: 1280px) {
    .thematicHeader {
        width: 80%;
    }
}

@media only screen and (max-width: 991px) {
    .sectionTitle {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 800px) {
    .sectionTitle {
        font-size: 32px;
    }
}

@media only screen and (max-width: 600px) {
    .thematicHeader {
        width: 90%;
    }
    .sectionTitle {
        font-size: 30px;
    }
}

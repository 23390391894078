.letterBoxForm {
    height: 330px;
    width: 700px;
    padding: 30px;
    border: 2px dashed #D8B74B;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}

.fieldsLetter {
    margin-bottom: 20px;
}

.fieldsLetterLeft {
    display: flex;
    justify-content: right;
}

.letterGreetings,
.letterBody,
.letterClosing {
    border: none;
    resize: none;
    color: rgb(0, 0, 0);
    font-size: 13px;
    line-height: 25px;
    background-image: -moz-linear-gradient(top , transparent, transparent 24px,#BDE0E2 0px); 
    background-image: -webkit-linear-gradient(top , transparent, transparent 24px,#BDE0E2 0);
    -webkit-background-size: 100% 25px;
    background-size: 100% 25px;
}

.letterGreetings {
    width: 400px;
    height: 30px;
}

.letterBody {
    width: 100%;
    height: 150px;    
}

.letterClosing {
    width: 400px;
    height: 30px;
}

.letterBoxForm textarea:focus {
    outline: none;
}

@media only screen and (max-width: 1270px) {
    .letterBoxForm {
        height: 300px;
        width: 650px;
        padding: 20px;
    }

    .fieldsLetter {
        margin-bottom: 15px;
    }

    .letterGreetings,
    .letterClosing {
        width: 300px;
    }

    .letterBody {
        height: 130px;
    }
}

@media only screen and (max-width: 1100px) {
    .letterBoxForm{
        height: 250px;
        width: 600px;
    }

    .fieldsLetter {
        margin-bottom: 0;
    }
}
.exerciseContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
}

.imagesWrapper {
    display: flex;
    justify-content: center;
}

.uploadFileBox {
    border-color: #D8B74B;
    border-style: dashed;
    border-width: 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5em;
    position: relative;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	overflow: hidden;
	position: absolute;
    z-index: -1;
}

.imgButton {
    background: transparent;
    border-color: transparent;
    color: #00868E;
    text-decoration: underline;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
}

.changeContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: -45px;
}

.btnMaxWidth {
    width: 180px;
    text-align: center;
}

.exerciseButtonsGroup {
    display: flex;
    justify-content: center;
}

.buttonsGroupWrapper {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 20px;
}

.buttonsGroupWrapper > *:not(:first-child) {
    margin-left: 10px;
}

.buttonsGroupWrapper a:hover {
    text-decoration: none;
}

.exerciseButtonNotSelected,
.exerciseButtonSelected {
    height: 25px;
    width: 32px;
    border-radius: 20px 20px 5px 5px;
    border-color: #D8B74D;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: black;
}

.exerciseButtonNotSelected {
    background-color: white;
}

.exerciseButtonSelected {
    background-color: #D8B74B;
}
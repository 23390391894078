.resourcesH3 {
    margin-bottom: 15px;
    margin-top: 50px;
    color: #00868E;
    font-weight: bold;
    text-decoration: underline;
}

.resourcesText {
    color: black;
    font-weight: 500;
    text-align: justify;
}

.resourceItemsContainer >* {
    padding-bottom: 50px;
    border-bottom: 2px solid #00868E;
    border-radius: 2%;
}

.itemContainer {
    margin-top: 50px;
    display: flex;
}

.resourceImgContainer {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    max-height: 300px;
    margin-right: 30px;
    border-radius: 5%;
}

.itemContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.itemTitle {
    margin-top: 0;
    margin-bottom: 15px;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
}

.itemSubtitle {
    margin-top: 0;
    margin-bottom: 15px;
    color: #00868E;
    font-weight: bold;
    text-transform: uppercase;
}

.dossierContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dossierContainer .btnRegistration {
    margin-left: unset;
    margin-right: unset;
}

.closingText {
    width: 60%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.closingText p {
    text-align: center;
}

.printBtn {
    background-color: #00868E;
    border-radius: 10%;
    padding: 15px;
    width: 60px;
    height: 60px;
    margin-left: 15px;
}

@media only screen and (max-width: 1500px) {
    .resourceImgContainer {
        max-width: 350px;
        max-height: 250px;
    }
}

@media only screen and (max-width: 991px) {
    .resourcesH3 {
        margin-top: 40px;
    }
    
    .resourceImgContainer {
        max-width: 300px;
        max-height: 250px;
    }
    
    .closingText {
        width: 75%;
    }

    .printBtn {
        background-color: unset;
        padding: unset;
        width: 40px;
        height: 40px;
        margin-left: 10px;
    }

    .printBtn path {
        fill: #00868E;
    }
}

@media only screen and (max-width: 764px) {
    .resourceImgContainer {
        max-width: 250px;
        max-height: 200px;
    }
}

@media only screen and (max-width: 650px) {
    .itemContainer {
        flex-direction: column;
    }

    .resourceImgContainer {
        max-width: unset;
        max-height: unset;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: unset !important;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .itemContainer img {
        max-width: 350px;
        max-height: 300px;
    }
    
    .closingText {
        width: 80%;
    }
}

@media only screen and (max-width: 450px) {
    .itemContainer img {
        max-width: 100%;
        max-height: 300px;
    }
    
    .closingText {
        width: 90%;
    }
}
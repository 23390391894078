.helpLink {
    margin: 20px 20px;
    position: fixed;
    z-index: 200;
    bottom: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00868E;
    color: #fff !important;
    padding: 13px 22px;
    border-radius: 30px;
    font-weight: 700;
    opacity: 0.8;
}

.helpLink svg {
    margin-right: 8px;
}

.helpLink:hover {
    opacity: 1;
    text-decoration: none;
}

.helpLink:active {
    box-shadow: 0 5px #666;
}

.accordionWidth {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.userGuideSection {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faqQuestion {
    color: #00868E;
}

.userGuideDesc,
.faqResponse {
    text-align: justify;
}

.userGuideImg {
    max-width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.MuiAccordionDetails-root {
    flex-direction: column;
}

.accordionWidth .active {
    background-color: white;
}

.accordionWidth .active:after {
    content: "\2212";
}

@media only screen and (max-width: 600px) {
    .helpLink {
        padding: 15px;
    }

    .helpLink svg {
        margin-right: 0;
    }

    .helpLinkTxt {
        display: none;
    }
}